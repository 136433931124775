import React from 'react'
import styled from 'styled-components'
import Header from './Header';
import Footer from './Footer';

const  ServicesPage = () => {
  return (
    <Container>
        <Header index={2}/>
        <Contain>
            <Squares>
                <Img>
                  <img src="../assets/highlights.png" />
                </Img>
                <Write>
                    <h3>Hospital to Home Services</h3>
                    <div>Sometimes it is difficult to fit into normal lifestyle that
                        will aid recovery after an operation
                        or prolonged stay in hospital.
                    </div>
                    <div>
                        We support you from the first day of your return home to the point you are able to do most
                        things for yourself.
                    </div>
                    <div>
                        Our services includes upervision of discharge arrangements
                        Food shopping, Help with washing, dressing and personal care
                        Physical assistance and support to move safely around the home
                        Light housekeeping, Accompaniment to appointments
                        Re-assessment of needs.
                    </div>
                </Write>
            </Squares>
            <Squares left={"auto"}>
              <Img left={"auto"}>
                  <img src="../assets/highlights.png" />
                </Img>
                <Write>
                    <h3>Palliative or End of Life Care</h3>
                    <div>Having a loved one who is terminally ill could be very distressing and mostly unbearable to see how the illness is affecting a loved one. 
                        Our Palliative Care  is about improving the quality of life of our client facing a life-threatening condition. 
                        It includes physical, emotional and spiritual care.
                        One common fact we have discovered over the years of experience working with terminally ill patients is that the family and the patient 
                        want to live out the rest of their life in dignity, comfort and serenity as much as possible. This is where Ornate Healthcare comes in. 
                        We provide specialist support with the day-to-day chaos and care so you and your loved ones could make the most of your time together.
                    </div>
                    <div>                    
                        Palliative care at home allows you to stay in the place you feel most comfortable, surrounded by memories and the people you love. 
                        With the one-to-one support of a compassionate carer, who is experienced in palliative care, you are guaranteed of specialist support whenever needed.
                    </div>
                </Write>
            </Squares>
            <Squares>
                 <Img>
                  <img src="../assets/highlights.png" />
                </Img>
                <Write>
                    <h3>Supported Living</h3>
                    <div>
                        We assist our clients to develop and acquire skills that will enable them lead a fulfilled and independent life. 
                        This could be in our accomodation or in the comfort of your home.
                        We initially get to know our clients on individual basis prior and during the transition into supported living; and our support includes but not limited to
                        <ul>
                            <li>Settling into new tenancy</li>
                            <li>Support with household duties and budgeting.</li>
                            <li>help with correspondence, and the payment of bills.</li>
                            <li>Meal preparations and personal care where necessary</li>
                        </ul>
                    </div>
                    <div>
                        We work with you, your family, your GP and professionals from a variety of other healthcare 
                        organisations to ensure that all medical needs and requirements are met.
                        All of the care we provide is built around the individual. So, as well as taking your medical needs into account, 
                        we’ll also consider your likes, dislikes, interests and personality. 
                        We can then confidently match you with a carer you have plenty in common with.
                    </div>
                </Write>
             </Squares>
             <Squares left={"auto"}>
                 <Img left={"auto"}>
                  <img src="../assets/highlights.png" />
                </Img>
                <Write>
                    <h3>Supported Living</h3>
                    <div>Live-in-Care</div>
                    <div>Research shows that three out of four of us want to remain in our own homes as we get older. For many of our older clients, 
                        our service has prevented hospital or care home admission, whilst improving health and wellbeing at home.
                    </div>
                    <div>
                    We tailor make a live-in-care plan involving a full time carer (depending on your needs) who will deliver professionalism and quality care.
                    Our live-in care for adults covers all ages across the UK and with many varying health conditions.
                    An efficient and effective live-in-care service is the one that assures and delivers consistency, independency and reliability. 
                    These are what Ornate Healthcare promises and delivers.
                    Contact us for more information and a no obligatory quote.
                    </div>
                </Write>
            </Squares>
            <Squares>
              <Img>
                  <img src="../assets/highlights.png" />
                </Img>
                <Write>
                    <h3>Supported Living</h3>
                    <div>Overnight Care</div>
                    <div>
                        Most reason why people need overnight Care is to either give the family or the main carer the opportunity to get a good rest or break. 
                        Some could be to ease a loved one’s anxiety about being alone at night.
                    </div>
                    <div>
                    There are two main types of Overnight care we offier, namely::
                    <ul>
                        <li>A sleeping night</li>
                        <li>A waking night</li>
                    </ul>
                    <div>
                        Based on your needs, we have structured the above overnight care to be flexible and accommodate you to the right level of care needed.
                        Find Out More
                    </div>
                    </div>
                </Write>
            </Squares>
             <Squares left={"auto"}>
                <Img left={"auto"}>
                  <img src="../assets/highlights.png" />
                </Img>
                <Write> 
                    <h3>Learning Disability Support</h3>
                    <div>
                        Life is precious and everyone have the right to enjoy it to the best of their ability. We do not believe in limitations; 
                        we believe the right individualised support could break any barrier as a result of learning disabilities. 
                        We provide you with experienced and trained support worker/ Carer who understands your needs and personality.
                        Ornate healthcare will work with you to creat a practical, efficient plan that woud provide you with the support needed to lead a fulfilled life.
                    </div>
                    <div>
                        We have the support structure of encouragement, empowerment and enablement so as to help you regain or maintain your confidence and independenceaswellas 
                        developing your social and communication skills and any other issues that may arise as a result of their learning disability.
                        Find Out More 
                    </div>
                </Write>
             </Squares>
              <Squares>
                <Img left={"auto"}>
                  <img src="../assets/highlights.png" />
                </Img>
                <Write>
                    <h3>Alzheimer’s and Dementia</h3>
                    <div>Maintaining a stable routines and a familiar home environment helps those with Alzheimer or other dementia to manage their condition.</div>
                    <div>Our friendly staff ensure your home environment remains safe and advise on any adaptions that will make life easier. We also work with clients and their loved ones to provide emotional support and respite, 
                        to access local services and to adapt the care package to ensure changing needs are anticipated and always met.
                        FIND OUT MORE
                    </div>
                </Write>
              </Squares>
              <BoottomPage>
                  <div id='Display'>Ethoes and Values</div>
                  <h4>DOMESTIC / PERSONAL ASSISTANT</h4>
                  <div>Due to age or health situation, we sometimes find it a bit difficult or hard to do the normalhouse chaos we would normally do with ease. This could become frustrating to you or your loved one. 
                        To be able to ask for help when struggling is a sign of strength and not weakness.
                        It might be a liitle assistance with cleaning, vacuuming, dusting, laundry, preparing delicious meals or running errands, etc. 
                        We have experienced and very polite team who are all DBS certified and willing to do all those duties in order to ease the pressure and keep your home exactly the way you like it.
                        We are a person/individual focused business that puts you in the centre of the service we deliver. 
                        This means that we understand that you have your own way of doing things and so we take careful note of your preferences and requirements.
                        You will be in control of the services you receive and we will endeavour to meet your own personal standard of housekeeping
                        We would be happy to provide a free assessment and consult on how best to support your domestic needs.
                    </div>
                    <h4>CARE HOME OR HOME CARE?</h4>
                    <div>
                       According to research and publication by the NHS, going into a care home is a major commitment – 
                       it involves changing where you live and potentially paying a lot of money for your ongoing accommodation and care needs.
                    </div>
                    <div>
                        The best advise is to consider alternatives which are effective and efficient as well as less disruptive; before making an informed decision.
                        Most people go into a residential or nursing home for the most reason which includes; demand in care delivery at home or when discharged from hospital could be daunting, and as such, the only option is a nursing home or residential care home.
                        Our expert advise is that you need to consider certain factors which includes cost of living in a care home against cost of receiving the same or even better level of care at the comfort of your home.
                        Consider the disruptions to your daily activities and routine as you will be one of many being cared for in the same facility as against your personal carer (s) in the comfort of your home and care package structured around your routine and lifestyle.
                    </div>
                    <h4></h4>
                    <div>
                        This is why at Ornate Healthcare, our starting point is listening and getting to know you so we can tailor your care around your individual needs. 
                        We provide the full range of care services in the home, from the round-the-clock support of live-in care through to visiting support when you need it most, 
                        whatever time of day or night. By choosing us over a care home, 
                        you are confident there will be constant care and companionship from a carer who is completely dedicated to you.
                    </div>
             </BoottomPage>
        </Contain>
    </Container>
  )
}

const Container = styled.div`
display:flex; 
width:100%; 
height:auto;
flex-flow:column;
`;



const Contain = styled.div`
width:80%; 
margin: 0 auto;
display:flex;
flex-flow:column;
padding-top:100px;
padding-bottom:100px;
@media(max-width:980px){
width:100%;
}
`;



const Squares = styled.div`
width:50%;
padding:20px;
font-size:12px;
margin-top:50px;
box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
margin-left:${(props) => props.left};
h3{
font-size:15px;
font-weight:900;
}
div{
margin-top:10px;
ul{
margin-top:20px;
}
ul li{
margin-left:30px;
}
}
@media(max-width:980px){
width:85%;
margin:10px;
padding:15px;
}
`;

const Img = styled.div`
display:flex;
img{
width:200px; 
height:150px; 
object-fit:contain;
margin-left:${(props) => props.left};
}
`;

const Write = styled.div``;



const BoottomPage = styled.div`
width:100%; 
height:auto;
margin-top:100px;
display:flex;
flex-flow:column;
padding-bottom:20px;
#Display{
margin: 0 auto;
font-weight:900;
font-size:25px;
padding:20px;
}
h4{
margin:15px;
}
div{
margin-left:15px;
}
@media(max-width:980px){
width:90%;
font-size:12px;
}
`;

export default ServicesPage