import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Services from './Services';
import { RiCalendar2Line } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';

const  Hero = () => {


  const history = useNavigate();

  return (
    <Container>

      <LeftWriteUp>
        <div data-aos={"fade-down-left"}>
          <h1>Care at Home</h1>
          <h4>Looking for help is a sign of strength</h4>
          <div id='btn'  onClick={(e) => history("/bookorder")}><RiCalendar2Line /> &nbsp; BOOK A FREE ASSESSMENT</div>
        </div>
      </LeftWriteUp>

      <RightLogo>
        <div data-aos={"fade-down-right"}>
           <img src="../assets/care-for-the-elderly_remove.png"/>
         </div>
      </RightLogo>
    </Container>
  )
}


const Container = styled.div`
display:flex;
flex-flow:row;
@media(max-width:980px){
overflow-x:hidden;
}

`;



const LeftWriteUp = styled.div`
display:flex;
flex-flow:column;
width:50%;
margin-top:100px;
margin-left:30px;
h1{
font-weight:900;
font-size:90px;
width:100%;
}
h4{
font-weight:500;
font-size:30px;
width:60%;
}
#btn{
display:flex;
width:250px;
height:30px;
background:#A5CE37;
text-align:center;
color:#ffffff;
padding:15px;
border-radius:10px;
margin-top:15px;
justify-content:center;
align-items:center;
box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
:hover{
background:#13B6FF;
transform: scale(1.05);
transition: transform 500ms;
z-index: 1;
}
}
@media(max-width:980px){
margin-left:10px;
h1{
font-size:40px;
}
h4{
font-size:20px;
margin-top:20px;
width:100%;
}
#btn{
font-size:12px;
width:100%;
margin-top:20px;
height:20px;
width:200px;
padding:10px;
}
}
`;



const RightLogo = styled.div`
width:40%;
height:80vh;
div{
width:100%;
height:100%;
img{
width:100%;
height:100%;
object-fit:contain;
}
}
@media(max-width:980px){
height:40vh;
margin-top:20px;
}
`;

export default Hero