import React,{useState} from 'react'
import styled from 'styled-components'
import Swal from 'sweetalert2';
import { UpdateEnquire } from '../Admin/Index';

const VoluteeModel = (props) =>{


const updateUser = () => {
    Swal.fire({
      icon:"info",
      title: `Confrim acion`,
      showCancelButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `Cancel`,
    }).then(async(result) => {
      if (result.isConfirmed) {
        // let res = await UpdateEnquire(props.v.email,props.v.isDisabled ? 1 : 0); 
        //  Swal.fire(` ${res.message} !`, '', 'success');
        //  setTimeout(()=> window.location.href = window.location.href,2000)
      } 
    })
}


  return (
    <Container>
      <table>
        <tr>
          <td>
           <button onClick={(e) => updateUser(e)}>Delete</button>
          </td>
        </tr>

        <tr>
          <td>
            <h5 style={{fontStyle:"bold",fontWeight:"900"}}>Fullname:</h5>
             {props.v.body.name}
          </td>
        </tr>
        <tr>
          <td>
             <h5 style={{fontStyle:"bold",fontWeight:"900"}}>Email:</h5>
              {props.v.body.email}
          </td>
        </tr>
        <tr>
          <td>
            <h5 style={{fontStyle:"bold",fontWeight:"900"}}>Phone:</h5>
            {props.v.body.phone}
          </td>
        </tr>
        <tr>
          <td>
            <h5 style={{fontStyle:"bold",fontWeight:"900"}}>Message:</h5>
            {props.v.body.message} 
          </td>
          </tr>
      </table>
    </Container>
  )
}

const Container = styled.div`
display:flex;
width:60%;
height:auto;
padding:10px;
font-size:12px;
margin-top:30px;
background:#fff;
box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
@media(max-width:760px){
width:90%;
margin:20px;
}
`;

export default VoluteeModel;