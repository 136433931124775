import React from 'react'
import  {RiHealthBookLine} from 'react-icons/ri'
import styled from 'styled-components'

function Allservice() {
  return (
    <Container>
        <LeftTab>
            <div data-aos={"fade-down-left"}>
                <h2>What we do</h2>
                <h4> 
                    LIVE-IN CARE
                    SUPPORTED LIVING
                    DOMICILIARY CARE
                    LEARNING DISABILITY 
                    COMPANIONSHIP / PA
                    DEMENTIA CARE
                    HEALTHCARE RECRUITMENT
                </h4>
            </div>
        </LeftTab>
        <RightTab> 
            <div data-aos={"fade-down-right"}>
                <img src="../assets/granny.png" />
                <div id='btn'>
                  <RiHealthBookLine /> &nbsp; Sell all services 
                </div>
            </div>
        </RightTab>
    </Container>
  )
}

const Container = styled.div`
display:flex; 
flex-flow:row;
justify-content:space-between;
width:80%; 
margin: 0 auto;
@media(max-width:980px){
width:100%;
flex-flow:column;
}
`;


const LeftTab = styled.div`
width:40%; 
margin-left:20px;
margin-top:150px;
h2{
font-weight:900;
font-style:bold;
font-size:45pt;
}
h4{
font-size:12px;
}
@media(max-width:980px){
width:90%;
align-items:center;
text-align:center;
h2{
font-size:35pt;
}
}
`;


const RightTab = styled.div`
display:flex;
flex-flow:column;
width:60%;
img{
margin-left:auto;
object-fit:contain;
width:100%;
height:400px;
}

#btn{
display:flex;
width:250px;
height:30px;
background:#53db25;
text-align:center;
color:#ffffff;
padding:15px;
border-radius:10px;
margin-top:5px;
justify-content:center;
align-items:center;
margin-left:auto;
margin-right:15px;
margin-bottom:15px;
box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
:hover{
background:#13B6FF;
transform: scale(1.05);
transition: transform 500ms;
z-index: 1;
}
}

@media(max-width:980px){
width:90%;
align-items:center;
text-align:center;
margin:0 auto;
img{
}
}

`;

export default Allservice