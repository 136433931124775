import axios from "axios"
import { MessagePop, SessionGet } from "./Reuse";




export const SendBooking = async (pay) => {
    try{
        let keys = {name:pay[0],address:pay[1],email:pay[2],contact:pay[3],aboutService:pay[4],time:pay[5],gender:pay[6],careList:pay[7]};
        let res = await axios.post(process.env.REACT_APP_BOOKED,keys);
         return res.data;
    }catch(err){
        MessagePop("Error",err,"error");
    }
}




export const SendEnquire = async (pay) => {
    try{
        let keys = {name:pay[0],email:pay[1],phone:pay[2],message:pay[3]};
        let res = await axios.post(process.env.REACT_APP_ENQUIRE,keys);
         return res.data;
    }catch(err){
        MessagePop("Error",err,"error");
    }
}






export const Send_Voluteer = async (pay) => {
    try{
        let keys = {name:pay[0],email:pay[1],phone:pay[2],message:pay[3]};
        let res = await axios.post(process.env.REACT_APP_Voluteer,keys);
         return res.data;
    }catch(err){
        MessagePop("Error",err,"error");
    }
}





export const SendApplicant = async (pay) => {
    try{
        let keys = {name:pay[0],
                   birth:pay[1],
                   gender:pay[2],
                   address:pay[3],
                   email:pay[4],
                   phone:pay[5],
                   national_insurance_number:pay[6],
                   position_apply_for:pay[7],
                   can_drive:pay[8],
                   shift_type:pay[9],
                   fullTime: pay[10],
                   work_permit:pay[11],
                   employment_history_1:pay[12],
                   jobRole1:pay[13],
                   employment_history_2:pay[14],
                   jobRole2:pay[15],
                   employment_history_3:pay[16],
                   jobRole3:pay[17],
                   education_history_1:pay[18],
                   education_history_2:pay[19],
                   refrerence1:pay[20],
                   refrerence2:pay[21],
                   aboutSelf:pay[22],
                   doa:pay[23], 
                  };
        let res = await axios.post(process.env.REACT_APP_Applicant,keys);
        return  res.data;  
    }catch(err){
        MessagePop("Error",err,"error");
    }
}








export const AdminRegister = async (pay) => {
    try{
        let keys = {email:pay[0],password:pay[1]};
        //
        let res = await axios.post(process.env.REACT_APP_REGISTER,keys);
         return res.data;
    }catch(err){
        MessagePop("Error",err,"error");
    }
}





export const AdminLogin = async (pay) => {
    try{
        let keys = {email:pay[0],password:pay[1]};
        //
        let res = await axios.post(process.env.REACT_APP_LOGIN,keys);
         return res.data;
    }catch(err){
        MessagePop("Error",err,"error");
    }
}







export const ListUserBookedOrders = async (pay) => {
    try{
        let tk = SessionGet();
        if(tk){
            let keys = {email:tk[0],token:tk[1]};
            //
            let res = await axios.post(process.env.REACT_APP_LIST_ENQUIRE,keys);
            return res.data;
        }
         else
           MessagePop("Session expired","Pls Reauthenticate.","error");
    }catch(err){
        MessagePop("Error",err,"error");
    }
}





export const ListUserVolutee = async (pay) => {
    try{
        let tk = SessionGet();
        if(tk){
            let keys = {email:tk[0],token:tk[1]};
            //
            let res = await axios.post(process.env.REACT_APP_LIST_VOLUTEE,keys);
            return res.data;
        }
         else
           MessagePop("Session expired","Pls reautenticate.","error");
    }catch(err){
        MessagePop("Error",err,"error");
    }
}




export const ListEnquires = async (pay) => {
    
    try{
        let tk = SessionGet();
        if(tk){
            let keys = {email:tk[0],token:tk[1]};
            //
            let res = await axios.post(process.env.REACT_APP_LIST_ENQUIRE_TAB,keys);
            return res.data;
        }
         else
           MessagePop("Session expired","Pls reautenticate.","error");
    }catch(err){
        MessagePop("Error",err,"error");
    }
}



export const ListUserApplicant = async (pay) => {
    try{
        let tk = SessionGet();
        if(tk){
            let keys = {email:tk[0],token:tk[1]};
            //
            let res = await axios.post(process.env.REACT_APP_LIST_APPLICANTE,keys);
            return res.data;
        }
         else
           MessagePop("Session expired","Please reautenticate.","error");
    }catch(err){
        MessagePop("Error",err,"error");
    }
}






export const UpdateEnquire = async (pay) => {

}




