import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import "aos/dist/aos.css";
import { RiCloseLine, RiMenu2Line } from 'react-icons/ri';


const  Header = (props) => {
    const [screens, setScreens] = useState("");
    const [left, setLeft] = useState("");
    const [choose, setServiceChoose] = useState("");
    const [open, setOpen] = useState(false);
    const [list,setList] = useState(["Home","About","Services","Contact","Work with us","More"])
    const [dropList,setdropList] = useState(["DOMICILARY CARE","SUPPORTED LIVING","LEARNING DISABILITY","LIVE IN CARE","COMPANIONSHIP/ PA","OVERNIGHT CARE","RECRUITMENTS & PLACEMENTS"])
    const [btn, setBtn] = useState(props.index ? props.index : 0);
    const [showModel, setshowModel] = useState(false);
    const active = {border:"1px solid #f5f5f5",background:"#13B6FF",color:"#fff"}
    const history = useNavigate();
    const inactive = {}
    
    


    function SendPage(v,i,z,s) {
      setScreens(s);
        if(i === 2){
           setLeft("60%");
           setshowModel(true);
        }
        if(z === 1){
            setBtn(i)
            setServiceChoose(v);
            history(`/${v === "Home" ? '' : Page(v)}`);
        }
        
        
    }

    

    const Page = (v) => {
       let url = v.toLowerCase().replace(/\s/g, '' );
       return url;
    }


    useEffect(() => {
        AOS.init();
        AOS.refresh();
      },[])

    return (
    <Container>
        <Logoname onClick={(e) => history("/")}>
            <img alt='Ornatehealthcare Logo' src="../assets/herologo.png"/>
            <div>Ornatehealthcare</div>
        </Logoname>
        <TopNavBarRight>
            {list.map((v,i) => <div style={btn == i ? active : inactive}  onClick={(e) =>SendPage(v,i,1,"b")}  onMouseEnter={(e) => SendPage(v,i,0,"b")} onMouseLeave={(e) =>  {screens === "b" ? setshowModel(false) : setshowModel(true) }}>{v}</div>)}
        </TopNavBarRight>

        {showModel  && screens === "b" ?
        <DropOff lf={left} onMouseEnter={(e) => setshowModel(true)}  onMouseLeave={(e) => setshowModel(false)}>
            <table data-aos="zoom-out-up">
                {dropList.map((v) => <tr onClick={(e) => SendPage("services",2,1)}><td><br/>{v}</td></tr>)}
            </table>
        </DropOff>
          :
          showModel  && screens === "s" ?

          <DropDw lf={left} onMouseEnter={(e) => setshowModel(true)}>
            <table data-aos="zoom-out-up">
                {dropList.map((v) => <tr onClick={(e) => SendPage("services",2,1)}><td><br/>{v}</td></tr>)}
            </table>
          </DropDw>
          :
          null
        }
        {open 
           ?
            <NavBuild>
                <div id='e1' onClick={(e) => setOpen(false)} >
                <RiCloseLine color='#000' size={30} />
              </div>
              {list.map((v,i) => <div style={btn == i ? active : inactive}  onClick={(e) =>SendPage(v,i,1,"s")}  onMouseEnter={(e) => SendPage(v,i,0,"s")} onMouseLeave={(e) => setshowModel(false)}>{v}</div>)}
            </NavBuild>
              : 
           ""
          }
        <NavBars>
          <RiMenu2Line onClick={(e) => setOpen(true)} color='#00000' size={30} />
        </NavBars>
    </Container>
  )
}


const Container = styled.div`
width:100%;
height:100px;
display:flex;
flex-flow:row;
justify-content:space-between;

`;



const DropOff = styled.div`
z-index:99999999999999;
position:absolute;
height:auto;
width:auto;
display:flex;
font-size:12px;
padding:25px;
color:#ffffff;
margin-top:50px;
background:#A5CE37;
border-radius:15px;
justify-content:center;
font-family: "Poppins", sans-serif;
margin-left:${(props) => props.lf};
table{
cursor:pointer;
}
@media(max-width:760px){
margin-left:10%;
}
`;



const DropDw = styled.div`
z-index:99999999999999;
position:absolute;
height:auto;
width:auto;
display:flex;
font-size:12px;
padding:25px;
color:#ffffff;
margin-top:250px;
background:#A5CE37;
border-radius:15px;
justify-content:center;
font-family: "Poppins", sans-serif;
table{
cursor:pointer;
}
@media(max-width:760px){
margin-left:10%;
}
`;



const Logoname = styled.div`
display:flex;
flex-flow:column;
margin:30px;
img{
width:100px;
height:100px; 
object-fit:contain;
}
div{
margin-top:5px;
font-weight:900;
font-style:bold;
font-size:15px;
}
`;




const TopNavBarRight = styled.div`
display:flex;
flex-flow:row;
div{
margin:10px;
height:30px;
padding:15px;
border-radius:10px;
:hover {
border:1px solid #f5f5f5;
background:#13B6FF;
color:#fff;
}
}
@media(max-width:760px){
display:none;
}
`;





const NavBars = styled.div`
display:none;
@media(max-width:988px){
display:block;
padding:15px;
}
`;


const NavBuild = styled.div`
background:#fff;
width:100%;
height:100vh;
display:flex;
flex-flow:column;
position:absolute;
z-index:99999999;
font-family: "Poppins", sans-serif;

div{
padding:10px;
width:auto;
margin-top:40px;
text-align:center;
border-radius:5px;
margin-left:auto;
margin-right:25px;
}
`;

export default Header