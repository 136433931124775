import React,{useState} from 'react'
import styled from 'styled-components'
import Swal from 'sweetalert2';
import { UpdateEnquire } from '../Admin/Index';

const AppModel = (props) =>{


const updateUser = () => {
    Swal.fire({
      icon:"info",
      title: `Confrim acion`,
      showCancelButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `Cancel`,
    }).then(async(result) => {
      if (result.isConfirmed) {
        // let res = await UpdateEnquire(props.v.email,props.v.isDisabled ? 1 : 0); 
        //  Swal.fire(` ${res.message} !`, '', 'success');
        //  setTimeout(()=> window.location.href = window.location.href,2000)
      } 
    })
}


  return (
    <Container>
      <table>
        <tr>
          <td>
            <div  style={{marginLeft:"auto"}}>
              <button onClick={(e) => updateUser(e)}>Update</button>
            </div>
          </td>
        </tr>

        <tr>
          <td>
           <h5 style={{fontWeight:"900",fontStyle:"bold"}}>Name:</h5>
            {props.v.body.name}
          </td>
         </tr>

         <tr>
          <td>
           <h5 style={{fontWeight:"900",fontStyle:"bold"}}>DOB:</h5>
            {props.v.body.birth}
          </td>
         </tr>


         <tr>
          <td>
           <h5 style={{fontWeight:"900",fontStyle:"bold"}}>Gender:</h5>
            {props.v.body.gender}
          </td>
         </tr>

         <tr>
          <td>
           <h5 style={{fontWeight:"900",fontStyle:"bold"}}>Address:</h5>
            {props.v.body.address}
          </td>
         </tr>

         <tr>
          <td>
           <h5 style={{fontWeight:"900",fontStyle:"bold"}}>Email:</h5>
            {props.v.body.email}
          </td>
         </tr>


         <tr>
          <td>
           <h5 style={{fontWeight:"900",fontStyle:"bold"}}>Phone:</h5>
            {props.v.body.phone}
          </td>
         </tr>


         <tr>
          <td>
           <h5 style={{fontWeight:"900",fontStyle:"bold"}}>National Insurance Number:</h5>
            {props.v.body.national_insurance_number}
          </td>
         </tr>



         <tr>
          <td>
           <h5 style={{fontWeight:"900",fontStyle:"bold"}}>Position Applied For:</h5>
            {props.v.body.position_apply_for.map((v) => <span key={v}>{v}, &nbsp;</span>)}
          </td>
         </tr>


         <tr>
          <td>
           <h5 style={{fontWeight:"900",fontStyle:"bold"}}>Can drive:</h5>
            {props.v.body.can_drive}
          </td>
         </tr>

         
         <tr>
          <td>
           <h5 style={{fontWeight:"900",fontStyle:"bold"}}>Shift type:</h5>
            {props.v.body.shift_type}
          </td>
         </tr>


         <tr>
          <td>
           <h5 style={{fontWeight:"900",fontStyle:"bold"}}>Full Time Selection:</h5>
           {props.v.body.fullTime.map((v) => <span key={v}>{v}, &nbsp;</span>)}
          </td>
         </tr>


         
         <tr>
          <td>
           <h5 style={{fontWeight:"900",fontStyle:"bold"}}>Work Permit:</h5>
            {props.v.body.work_permit}
          </td>
         </tr>



         <tr>
          <td>
           <h5 style={{fontWeight:"900",fontStyle:"bold"}}>Work Employment history 1:</h5>
            {props.v.body.employment_history_1}
          </td>
         </tr>
         


         <tr>
          <td>
           <h5 style={{fontWeight:"900",fontStyle:"bold"}}>Job Role 2:</h5>
            {props.v.body.jobRole1}
          </td>
         </tr>


         <tr>
          <td>
           <h5 style={{fontWeight:"900",fontStyle:"bold"}}>Work Employment history 2:</h5>
            {props.v.body.employment_history_2}
          </td>
         </tr>

         <tr>
          <td>
           <h5 style={{fontWeight:"900",fontStyle:"bold"}}>Job Role 2:</h5>
            {props.v.body.jobRole2}
          </td>
         </tr>



         <tr>
          <td>
           <h5 style={{fontWeight:"900",fontStyle:"bold"}}>Work Employment history 3:</h5>
            {props.v.body.employment_history_3}
          </td>
         </tr>

         <tr>
          <td>
           <h5 style={{fontWeight:"900",fontStyle:"bold"}}>Job Role 3:</h5>
            {props.v.body.jobRole3}
          </td>
         </tr>


         <tr>
          <td>
           <h5 style={{fontWeight:"900",fontStyle:"bold"}}>Education History 1:</h5>
            {props.v.body.education_history_1}
          </td>
         </tr>

         <tr>
          <td>
           <h5 style={{fontWeight:"900",fontStyle:"bold"}}>Education History 2:</h5>
            {props.v.body.education_history_2}
          </td>
         </tr>


         <tr>
          <td>
           <h5 style={{fontWeight:"900",fontStyle:"bold"}}>Refrerence 1:</h5>
            {props.v.body.refrerence1}
          </td>
         </tr>

         <tr>
          <td>
           <h5 style={{fontWeight:"900",fontStyle:"bold"}}>Refrerence 2:</h5>
            {props.v.body.refrerence2}
          </td>
         </tr>


         <tr>
          <td>
           <h5 style={{fontWeight:"900",fontStyle:"bold"}}>About self:</h5>
            {props.v.body.aboutSelf}
          </td>
         </tr>


         <tr>
          <td>
           <h5 style={{fontWeight:"900",fontStyle:"bold"}}>Date of Application:</h5>
            {props.v.body.doa}
          </td>
         </tr>

         
         
         
         
      </table>
    </Container>
  )
}

const Container = styled.div`
display:flex;
width:60%;
height:auto;
padding:10px;
font-size:12px;
margin-top:30px;
background:#fff;
box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
table{
width:100%;
tr td{
display:flex;
flex-flow:column;
}
}
@media(max-width:760px){
width:90%;
margin:20px;
}
`;

export default AppModel