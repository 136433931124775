import React, { useState } from 'react'
import styled from 'styled-components'
import HomePageM from '../Models/HomePageM'

const  Services = () => {

 const [urls, setUrl] = useState([{url:"../assets/sintomas-demencia.jpg",
                                   info:"Learning Disability Support",
                                   text:`We support our clients to develop and acquire the skills to enable them lead a fulfilled life.
                                   We commit the time and resources to know our client as an individual, understand their need, empower and provide the support they need. 
                                   Our Learning Disability Support services could be in the community; at the comfort of your home or in one of our accomodations.
                                   We would be able to support you with household budgeting, help with correspondence, shopping assistant, meal preparation and payment of bills.`},
                                   {
                                    url:"../assets/liveincare.png",
                                    info:"Live-in-Care",
                                    text:`We undertand that your home is your safe haven filled with memories. Some people do not like the option of leaving their homes, but would rather have the desired support and care by a professional in the comfort of their home.
                                    At Ornate Healthcare, we ensure that we tailor make a live-in-care plan involving a full time carer (depending on your needs) who will deliver professionalism and quality care to enable you lead a fulfilled life in your home.`
                                   },
                                   {
                                    url:"../assets/live-in-care.jpg",
                                    info:"Homecare/Domiciliary",
                                    text:`Our Homecare services is person centered care that promotes independence, dignity in care, and respect.
                                    Our Carers are trained and experienced professionals. Our Carers would  effortlessly provide a dignified personal care to you in the comfort of your home.
                                    Whatever suport you require, we deliver professionalism and quality. We are also affordable and reliable.`
                                   }])

  return (
    <Container>
        <div id='n1'>
          Services we offer
        </div>
        <div id='n2'>
           We are a client focused care company, started originally in the United Kingdom and with a branch office in Nigeria
        </div>
        <Table>
           {urls.map((v,i) => <HomePageM v={v} key={i} /> )}
        </Table>
    </Container>
  )
}

const Container = styled.div`
width:80%;
display:flex;
justify-content:center;
text-align:center;
align-items:center;
flex-flow:column;
margin-left:auto;
margin-right:auto;
margin-top:100px;
#n1{
width:100%;
height:20px;
color:#f26f11;
font-weight:900;
font-size:35px;
}
#n2{
margin-top:35px;
width:80%;
font-weight:900;
}
@media(max-width:980px){
width:100%;
}
`;



const Table = styled.div`
display:flex;
justify-content:center;
align-items:center;
flex-flow:row;
margin-top:100px;
@media(max-width:980px){
flex-flow:column;
}
`;



export default Services