import React, { useState } from 'react'
import styled from 'styled-components'
import { Form,Input,Submit} from './ContactUs';
import {RiAccountCircleLine} from 'react-icons/ri';
import { Oval } from 'react-loader-spinner';
import { Send_Voluteer } from '../Admin/Index';
import { MessagePop, Trim } from '../Admin/Reuse';

const More = ()  => {


 const  [name, setName] = useState("");
 const  [email, setEmail] = useState("");
 const  [phone, setPhone] = useState("");
 const  [msg, setMsg] = useState("");
 const  [load, setLoad] = useState("");


 const SendRegisteration = async () => {
    if(Trim(name) && Trim(email) && Trim(phone) && Trim(msg)){
           setLoad(true);
           let res = await Send_Voluteer([name,email,phone,msg]);
           if(res){
             setLoad(false);
             MessagePop("Info",res.message,"success");
             setName(""); setEmail(""); setPhone(""); setMsg("");
           }
      }else 
         MessagePop("Info","Pls all fields are mandatory.","info")
 }

  return (
    <Container>
         <Discailmer>
            <h1>SUPPORTING THE COMMUNITY</h1>
            <h5>The Pandemic has affected so many lives and families in different ways. 
                 We have witnessed the devastating effects on people's livelihood, mostly the people within our communities. 
                 Howbeit, the community spirit has been overwhelming and with such encouragements and mutual support, we believe we will get through it
            </h5>
            <h5>
                As a business in the Healthcare sector, 2020 has been a very challenging year, but like everyone, 
                we keep our spirit high, reaffirms our commitments to our clients and community.
            </h5>
            <h5>
                As part of our Coperate Social Commitments, we supported some local projects within the community and we are willing to do more.
                Contact us if there is a way we could work together to promote your community projects especially during this Pandemic.
            </h5>
        </Discailmer>
    
        <Discailmer>
            <h1>PROJECTS</h1>
            <h5>ORNATE HEALTHCARE MADE FINANCIAL DONATIONS TO LOCAL FOOD BANK PROJECTS.</h5>
            <h5>Voluteer or apply to work with our community interest projects contact@ornatehealthcare.co.uk</h5>

            <Form>
               Fill out the form below to tell us how we could work with you on your community Projects.
                <Input>
                    <RiAccountCircleLine size={35} color='#e1e1e1' />
                    <input placeholder='Name' type='text'  value={name}  onChange={(e) => setName(e.target.value)}/>
                </Input>
                <Input>
                    <RiAccountCircleLine size={35} color='#e1e1e1' />
                    <input placeholder='Email*' type='text'  value={email}  onChange={(e) => setEmail(e.target.value)}/>
                </Input>
                <Input>
                    <RiAccountCircleLine size={35} color='#e1e1e1' />
                    <input placeholder='Phone *' type='text'  value={phone}  onChange={(e) => setPhone(e.target.value)}/>
                </Input>
                <Input>
                    <textarea placeholder='Message*' type='text'  value={msg}  onChange={(e) => setMsg(e.target.value)}/>
                </Input>
                <br/>
                <Submit onClick={(e) => SendRegisteration()}> {!load ? "SEND" : <Oval color='#fff' height={20} width={20} />}</Submit>
            </Form>
        </Discailmer>
    </Container>
  )
}


const Container = styled.div`
display:flex; 
flex-flow:row;
width:80%;
height:150vh;
margin:0 auto;
justify-content:space-between;
#btnBottom{
margin-left:20px;
margin-top:20px; 
h4{
margin-left:30px;
}
}
@media(max-width:768px){
width:100%;
flex-flow:column;
height:180vh;
margin-bottom:250px;
}
`;

const Discailmer = styled.div`
width:50%;
height:200px;
margin-top:50px;
margin-left:10px;
text-align:center;
h5{
text-align:left;
font-size:11px;
padding:10px;
}
h1{
padding:15px;
}
@media(max-width:768px){
width:90%;
height:auto;
margin-top:30px;
margin-bottom:30px;
}
`;


const Menu = styled.div`
width:auto; 
height:50px;
margin-top:20px;
margin-left:40px;
div{
display:flex;
}

`;

export default More