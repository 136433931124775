import React, { useState } from 'react'
import styled from 'styled-components'
import { Form, Input, Submit } from '../Components/ContactUs';
import { Oval } from 'react-loader-spinner';
import {RiAccountCircleLine, RiLock2Line} from 'react-icons/ri';
import { AdminRegister } from './Index';
import { MessagePop, Trim } from './Reuse';


const Register = () => {
  const [load, setLoad] = useState(false);
  const  [email, setEmail] = useState("")
  const  [password, setPassword] = useState("")


  const SendRegisteration = async () => {
    if(Trim(email)  && Trim(password)){
       setLoad(true);
       let res = await AdminRegister([email,password])
       if(res){
          if(res.message.includes("created")){
            setLoad(false);
            setEmail(""); setPassword("");
            window.location.href = "/clog" 
          }
          MessagePop("Info",res.message,"info");
        }
    }else
       MessagePop("Info","Pls all fields are mandatory.","info")
  }

  return (
    <Container>
        <Form>
         Admin Registration
        <Input>
            <RiAccountCircleLine size={35} color='#e1e1e1' />
            <input placeholder='Email *' type='email'  value={email}  onChange={(e) => setEmail(e.target.value)}/>
        </Input>
        <Input>
            <RiLock2Line size={35} color='#e1e1e1' />
            <input placeholder='Password *' type='password'  value={password}  onChange={(e) => setPassword(e.target.value)}/>
        </Input>
        <br/>
            <Submit onClick={(e) => SendRegisteration()}> {!load ? "Register" : <Oval color='#fff' height={20} width={20} />}</Submit>
        </Form>
    </Container>
  )
}

const Container = styled.div`
width:50%; 
height:100vh;
margin-left:auto;
margin-right:auto;
margin-top:10%;
@media(max-width:980px){
width:90%;
margin-top:50%;
}
`;

export default Register