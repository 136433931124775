import logo from './logo.svg';
import './App.css';
import {BrowserRouter,Routes,Router,Route} from "react-router-dom";
import Hero from './Components/Hero';
import Header from './Components/Header';
import Services from './Components/Services';
import Allservice from './Components/Allservice';
import SecondToFooter from './Components/SecondToFooter';
import Footer from './Components/Footer';
import About from './Components/About';
import ServicesPage from './Components/ServicesPage';
import ContactUs from './Components/ContactUs';
import WorkUs from './Components/WorkUs';
import More from './Components/More';
import BookOrder from './Components/BookOrder';
import Login from './Admin/Login';
import Register from './Admin/Register';
import ListUsersEnquire from './Admin/ListUsersEnquire';
import CHeader from './Admin/CHeader';
import ListUsersVolutee from './Admin/ListUsersVolutee';
import ListUsersBooked from './Admin/ListUsersBooked';
import ListUsersApplicant from './Admin/ListUsersApplicant';


function App() {
  return (
    <div className="App">
        <BrowserRouter>
        <Routes>  
            <Route exact path="/"  element={<Header/>}/>          
          </Routes>
          <Routes>  
            <Route exact path="/"  element={<Hero/>}/>          
          </Routes> 
          <Routes>  
            <Route exact path="/"  element={<Services/>}/>          
          </Routes> 
          <Routes>  
            <Route exact path="/"  element={<Allservice/>}/>          
          </Routes> 
          <Routes>  
            <Route exact path="/"  element={<SecondToFooter/>}/>          
          </Routes> 
          <Routes>  
            <Route exact path="/"  element={<Footer/>}/>          
          </Routes> 
          <Routes>  
            <Route exact path="/about"  element={<About/>}/>          
          </Routes> 
          <Routes>  
            <Route exact path="/about"  element={<Footer/>}/>          
          </Routes>
          <Routes>  
            <Route exact path="/services"  element={<ServicesPage/>}/>          
          </Routes> 
          <Routes>  
            <Route exact path="/services"  element={<Footer/>}/>          
          </Routes> 
          <Routes>  
            <Route exact path="/contact"  element={<Header index={3}/>}/>          
          </Routes> 
          <Routes>  
            <Route exact path="/contact"  element={<ContactUs/>}/>          
          </Routes> 
          <Routes>  
            <Route exact path="/contact"  element={ <Footer/>}/>          
          </Routes> 
          <Routes>  
            <Route exact path="/workwithus"  element={ <Header index={4}/>}/>          
          </Routes>
          <Routes>  
            <Route exact path="/workwithus"  element={ <WorkUs/>}/>          
          </Routes>
          <Routes>  
            <Route exact path="/workwithus"  element={ <Footer/>}/>          
          </Routes>
          <Routes>  
            <Route exact path="/more"  element={ <Header index={5}/>}/>          
          </Routes>
          <Routes>  
            <Route exact path="/more"  element={ <More/>}/>          
          </Routes>
          <Routes>  
            <Route exact path="/more"  element={ <Footer/>}/>          
          </Routes>
          <Routes>  
            <Route exact path="/bookorder"  element={ <Header/>}/>          
          </Routes>
          <Routes>  
            <Route exact path="/bookorder"  element={ <BookOrder/>}/>          
          </Routes>
          <Routes>  
            <Route exact path="/bookorder"  element={ <Footer/>}/>          
          </Routes>
          <Routes>  
            <Route exact path="/clog"  element={ <Login/>}/>          
          </Routes>
          <Routes>  
            <Route exact path="/creg"  element={ <Register/>}/>          
          </Routes>
          <Routes>  
            <Route exact path="/cpanel"  element={ <CHeader/>}/>          
          </Routes>
          <Routes>  
            <Route exact path="/cpanel"  element={ <ListUsersBooked/>}/>          
          </Routes>
          <Routes>  
            <Route exact path="/voluteer"  element={ <CHeader/>}/>          
          </Routes>
          <Routes>  
            <Route exact path="/voluteer"  element={ <ListUsersVolutee/>}/>          
          </Routes>
          <Routes>  
            <Route exact path="/applicants"  element={ <CHeader/>}/>          
          </Routes>
          <Routes>  
            <Route exact path="/applicants"  element={ <ListUsersApplicant/>}/>          
          </Routes>
          <Routes>  
            <Route exact path="/enquire"  element={ <CHeader/>}/>          
          </Routes>
          <Routes>  
            <Route exact path="/enquire"  element={ <ListUsersEnquire/>}/>          
          </Routes>
          <Routes>  
            <Route exact path="/auth"  element={ <Login/>}/>          
          </Routes>
          <Routes>  
            <Route exact path="/auth2"  element={ <Register/>}/>          
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
