import Swal from "sweetalert2";


export const Trim = (v) => {
    return v.trim().length > 0 ? true : false;
}



export const MessagePop = (name,text,icon) => {
    return Swal.fire({title:name,text:text,icon:icon})
}


export const SessionStore = (v) => {
    sessionStorage.setItem("token",JSON.stringify(v));
}
export const SessionGet = (v) => {
   return JSON.parse(sessionStorage.getItem("token"));
}



export const  CheckResponse = (res) => {
    if(JSON.stringify(res.message) !== null && JSON.stringify(res.message) !== undefined && !JSON.stringify(res.message).includes("{}"))
         if(res.message.length <= 0)
            MessagePop("No data Yet","List is Empty","info") 
        else
           if(res.message.includes("Session expired pls Login"))
                MessagePop("Pls reautenticate.",res.message,"error");
           else
              return true;
    else
      return false;
}