import React from 'react'
import styled from 'styled-components';

const SecondToFooter = () => {
  return (
    <Container> 
        <UpTab>

        <ImgCover>
            <div  data-aos="zoom-out-up">
            <img src="../assets/supported-living-350-2.jpg"/>
            </div> 
        </ImgCover> 
            <Same>
                <div  data-aos="zoom-out-up">
                    <Head>Why we are different from other Care Agencies</Head>
                    <Shead>We are different from other Care and Support Service Providers because we:</Shead>
                    <List>
                        <ul>
                            <li>LISTEN TO YOU</li>
                            <li>GET YOU INVOLVE</li>
                            <li>RESPECT YOUR CHOICE</li>
                            <li>COMPLIANT WITH REGULATIONS AND LEGISLATIONS</li>
                            <li>ALWAYS AVAILABLE AND ACCESSIBLE</li>
                            <li>TRANSPARENT AND WELL DOCUMENTED</li>
                            <li>VERY AFFORDABLE</li>
                        </ul>
                    </List>
                </div>
            </Same>
      </UpTab>
      <DownTab>
        <Page>
            <div  data-aos="zoom-out-up">
                <img src="../assets/highlights.png" />
                <span>
                    When we commit to provide our care and support services, we deliver on our promise. We do not cut corners, 
                    such as delivering a 15 minutes service where we are contracted for 60 minutes. 
                    This is because we  ensure we effectively use the allocated time to complete tasks properly and to observe any changes in the service user's 
                    well-being and respond accordingly.
                </span>
            </div>
        </Page>
        <Page>
            <div  data-aos="zoom-out-up">
            <img src="../assets/highlights.png" />
            <span>
                Our Carers and Support Workers are trained to deliver quality and personalised Care and Support Services that is person centred, 
                creates satisfaction for the service users; allowing them to live life independently in their own homes. Our wide range of services, 
                along with our commitment to making a difference every day, ensures peace of mind for our clients, service users and their families.
            </span>
           </div>
        </Page>
        <Page>
            <div  data-aos="zoom-out-up">
            <img src="../assets/highlights.png" />
            <span>
                As an organisation that is motivated and committed to delivering professional and quality bespoke services,  
                compliance and regulatory efficacy means we are aware of all our staff training and documentation and our employees are encouraged to maintain and 
                update all their skills on a regular basis so that they are up to date with the latest care methods/techniques and ways of looking after our client.
            </span>
           </div>
        </Page>
      </DownTab>
    </Container>
  )
}

const Container = styled.div`
width:80%;
display:flex; 
justify-content:center;
flex-direction:column;
margin-left:auto;
margin-right:auto;
margin-top:120px;
@media(max-width:980px){
width:100%;
}
`;



const UpTab = styled.div`
display:flex;
width:100%;
justify-content:space-between;
@media(max-width:980px){
width:100%;
flex-flow:column;
}
`;


const ImgCover = styled.div`
img{
height:500px; 
width:350px;
object-fit:cover;
}
@media(max-width:980px){
img{
width:100%;
}
}
`;

const Same = styled.div`
margin-top:100px;
align-items:center;
@media(max-width:980px){
padding:10px;
}
`;

const Head = styled.div`
font-weight:800;
font-size:25px;
font-style:bold;
`;


const Shead = styled.div``;



const List = styled.div`
margin-top:20px;
ul li{
margin-left:30px;
}
`;



const DownTab = styled.div`
width:100%;
`;


const Page = styled.div`
div{
margin-top:20px;
font-size:15px;
display:flex;
justify-content:center;
align-items:center;
img{
width:200px; 
height:200px; 
object-fit:contain;
}
span{
padding:20px;
font-size:13px;
width:90%;
}
}
@media(max-width:980px){
width:100%;
div{
display:flex;
flex-flow:column;
}
}
`;

export default  SecondToFooter;