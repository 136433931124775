import React, { useEffect } from 'react'
import styled from 'styled-components'
import Header from './Header';
import Footer from './Footer';

const About = () => {

  useEffect(() => {


  },[])  

  return (
    <Container>
        <Header index={1}/>
            <Contain>
                <TopPage>
                    <Tabs>
                    <h4>Hello and welcome to Ornate Healthcare nigeria</h4>
                        <div className='normal' style={{fontStyle:"italic"}}>
                            Ornate Healthcare Service is the trading name of Highland Ornate Limited; a Home Care and Supported Living Company 
                            registered the United Kingdom with the CQC to provide personal care services to adults, 
                            and incorporated in Nigeria with fully trained carers
                        </div>
                        <div className='normal'>
                            We are committed to building reputation and inspiring quality in the care and supported living sector through
                            efficient and effective service delivery and customer satisfaction.
                        </div>
                        <div className='normal'>
                        <span id='Bold'>Our Staff</span>
                            <br/>
                            Our staff provides excellent supported living, domiciliary care and other support services to a wide range of people with physical disabilities, 
                            acquired brain injuries (ABIs), learning disabilities, autism and mental health issues.
                            Our rigorous selection process, which includes personal interviews, competency testing, psychometric testing, reference checks and background clearance 
                             ensures that only the right individual with the right attitude will deliver our services.
                        </div>
                        <div className='normal'>
                        <span id='Bold'>Compliance</span>
                            <br/>
                            Our compliance system means we are aware of all our staff training and documentation and our employees are encouraged to 
                            maintain and update all their skills on a regular basis so that they are up to date with the latest care methods and ways of looking after our clients.
                        </div>
                    </Tabs>
                    <Tabs>
                    <h4>Why Choose Us</h4>
                    <div className='normal'>
                            We recognized that families have need for caregivers and support services that understands the client and treats them with 
                            dignity and respect as individual and not a job.
                    </div>
                    <div className='normal'>
                        Ornate Healthcare is a business that is built on these prinicple; creating trust and commitment, and affordability.
                        As a business, we understand what a successful relationship between a caregiver and client entails, as skills are not enough; 
                        personality of the worker  and compatibility with the Service User are crucial.
                    </div>
                    <div className='normal'>                    
                        We are distinguished from the high street because we tailor our services to the needs of our clients; 
                        we are more result and quality driven as against profit and numbers. We have a strong screening process for registered caregivers.
                        Using a regulated and efficient care company like ours means you are in control and have more choices.
                    </div>
                    <div className='normal'>
                            We are a family business and as such have great family values at heart; building our business on the strength of the 
                            reputation in our local community and the service we deliver.
                    </div>
                    </Tabs>
                </TopPage>
                <Bottom>
                    <LeftPage>
                        <Vision>
                            Our Vision
                            <br/>
                            Our vision is to be the number one care provider that inspires trust, hope and confidence among patients, partners, 
                            locals and professionals alike, through the quality of service and care we provide
                       </Vision>
                       <Values>
                            <h4>Ethos and Values</h4>
                            Ornate Healthcare Services is committed to delivering quality care, maintaining professionalism at all times, 
                            treating all equally with fairness and integrity. 
                            These are our core values which is instilled into the minds and attitude of our carers and the service users.
                       </Values>
                    </LeftPage>
                   <Face>
                     <img src="../assets/aboutface.png" />
                   </Face>
                </Bottom>
            </Contain>
    </Container>
  )
}


const Container = styled.div`
width:100%;
height:100vh;
@media(max-width:980px){
height:450vh;
}

`;


const Contain = styled.div`
display:flex; 
flex-flow:column;
justify-content:space-between;
width:80%;
margin: 0 auto;
`;


const Tabs = styled.div`
width:45%;
height:500px;
margin-top:50px;
text-align:center;
box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
h4{
padding:10px;
}
.normal{
text-align:left;
font-size:11px;
padding:15px;
#Bold{
font-weight:900;
font-size:14px;
}    
}
@media(max-width:980px){
width:100%;
height:auto;
}
`;



const TopPage = styled.div`
display:flex;
flex-flow:row;
width:100%; 
justify-content:space-between;
@media(max-width:980px){
flex-flow:column;
}
`;


const Bottom = styled.div`
width:100%;
height:600px;
display:flex;
flex-flow:row;
padding-bottom:50px;
@media(max-width:980px){
flex-flow:column;
}
`;


const LeftPage = styled.div`
width:40%;
margin-top:100px;
@media(max-width:980px){
width:90%;
}
`;



const Vision = styled.div`
width:100%;
height:50%;
color:#ffffff; 
padding:15px;
display:flex;
flex-flow:row;
background:#887BCA;
opacity:2em;
border-radius:10px;
justify-content:center;
align-items:center;
@media(max-width:980px){
height:auto;
}
`;



const Face = styled.div`
width:50%;
margin-top:100px;
margin-left:auto;
img{
height:100%;
width:100%; 
background:#F38224;
object-fit:contain;
border-radius:100%;
}

@media(max-width:980px){
width:100%;
}
`;



const Values = styled.div`
margin-top:50px;
@media(max-width:980px){
margin-bottom:50px;
}
`;




export default About