import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Form,Input,Submit} from './ContactUs';
import {RiAccountCircleLine} from 'react-icons/ri';
import { Oval } from 'react-loader-spinner';
import { MessagePop, Trim } from '../Admin/Reuse';
import {SendApplicant} from '../Admin/Index'

const WorkUs = ()  => {


 const  [name, setName] = useState("");
 const  [birth, setbirth] = useState("");
 const  [address, setaddress] = useState("");
 const  [email, setEmail] = useState("");
 const  [phone, setPhone] = useState("");
 const  [InsuranceNo, setInsuranceNo] = useState("");
 const  [employment1, setEmployment1] = useState("");
 const  [jobRole, setjobRole] = useState("");
 const  [employment2, setEmployment2] = useState("");
 const  [jobRole2, setjobRole2] = useState("");
 const  [employment3, setEmployment3] = useState("");
 const  [jobRole3, setjobRole3] = useState("");
 const  [educationhis1, seteducationhis1] = useState("");
 const  [educationhis2, seteducationhis2] = useState("");
 const  [aboutSelf, setaboutSelf] = useState("");
 const  [ref1, setRef1] = useState("");
 const  [ref2, setRef2] = useState("");
 const  [doa, setDoa] = useState("");
 const  [load, setLoad] = useState(false);
 const [gender, setGender] = useState("Select Gender");
 const [drive, setDrive] = useState("Select option");
 const [shift, setShift] = useState("Select option");
 const [work, setWork] = useState("Select option");
 const [YesNo, setYesNo] = useState(["Select option","Yes","No"]);
 const [workPermit, setworkPermit] = useState(["Select option","Uk Passport","Indefinite Leave to Remain","Timmed Leave to Remain","Others"]);
 const [genderList, genderisList] = useState(["Select Gender","Male","Female","Perfer not to say"]);
 const [shifts, setShifts] = useState(["Select option","Weekend only care","Mon-Fri","Bank staff"]);
 const   [l1, setL1] = useState([]);
 const   [l2, setL2] = useState([]);



 const handleOnChange1 = (v) => {
    if(!l1.includes(v.target.value)){
        l1.push(v.target.value);
        console.log(l1);
    }
 }




 const handleOnChange = (v) => {
    if(!l2.includes(v.target.value)){
        l2.push(v.target.value);
        console.log(l2);
    }
 }


 
 const handeleGenderChange =(e) => {
    console.log(e);
    setGender(e.target.value);
}


const handeleDriveChange =(e) => {
    console.log(e);
    setDrive(e.target.value);
}


const handeleShiftChange =(e) => {
    console.log(e);
    setShift(e.target.value);
}


const handeleWorkChange =(e) => {
    console.log(e);
    setWork(e.target.value);
}


useEffect(() => {

},[])

 const SendRegisteration =  async () => {

    if(Trim(name) && Trim(birth) && Trim(address) && Trim(email) && Trim(phone) && Trim(InsuranceNo) && Trim(jobRole)
    && Trim(employment1) && Trim(employment2) && Trim(jobRole2) && Trim(employment3) && Trim(jobRole3) && Trim(educationhis1) && Trim(educationhis2)
       && Trim(aboutSelf) && Trim(ref1) && Trim(ref2) && Trim(doa)){
         if(l1.length < 0 || l2.length < 0)
            MessagePop("Info","Pls all checkbox's are mandatory.","info")
         else
            if(gender !== "Select Gender" &&  drive !== "Select option" && shift !== "Select option" && work !== "Select option"){
                setLoad(true);
                let res = await SendApplicant([name,birth,gender,address,email,phone,InsuranceNo,l1,drive,shift,l2,work,employment1,jobRole,employment2,jobRole2,employment3,jobRole3,educationhis1,educationhis2,ref1,ref2,aboutSelf,doa]);      
                  if(res){
                    setLoad(false);
                    MessagePop("Info",res.message,"info")
                    l1 = []; l2 = [];
                    //window.location.href = window.location.href;
                  }
            }else
               MessagePop("Info","Pls all DropDown are mandatory.","info")

       }else
           MessagePop("Info","Pls all fields are mandatory.","info")
 }



  return (
    <Container>
        <Form>
           APPLICATION FORM
            <Input>
                <RiAccountCircleLine size={35} color='#e1e1e1' />
                <input placeholder='Applicant Full Name*' type='text'  value={name}  onChange={(e) => setName(e.target.value)}/>
            </Input>
            <Input>
                <RiAccountCircleLine size={35} color='#e1e1e1' />
                <input placeholder='Date of birth*' type='text'  value={birth}  onChange={(e) => setbirth(e.target.value)}/>
            </Input>
            <Menu>
                <select onChange={handeleGenderChange}>
                    {genderList.map((option) => (
                    <option key={option} value={option}>{option}</option>
                    ))}
                </select>
            </Menu>
            <Input>
                <RiAccountCircleLine size={35} color='#e1e1e1' />
                <input placeholder='FULL ADDRESS *' type='text'  value={address}  onChange={(e) => setaddress(e.target.value)}/>
            </Input>
            <Input>
                <RiAccountCircleLine size={35} color='#e1e1e1' />
                <input placeholder='Email*' type='text'  value={email}  onChange={(e) => setEmail(e.target.value)}/>
            </Input>
            <Input>
                <RiAccountCircleLine size={35} color='#e1e1e1' />
                <input placeholder='Contact Phone Number *' type='number'  value={phone}  onChange={(e) => setPhone(e.target.value)}/>
            </Input>
            <Input>
                <RiAccountCircleLine size={35} color='#e1e1e1' />
                <input placeholder='National Insurance Number *' type='text'  value={InsuranceNo}  onChange={(e) => setInsuranceNo(e.target.value)}/>
            </Input>

             <Menu>
                 POSITION APPLYING FOR *
                <div>
                 <input value={"HOME-CARE ASSISTANCE"} type="checkbox"  onChange={(e) => handleOnChange1(e)}/>
                 <h5>HOME-CARE ASSISTANCE</h5>
                </div>
                <div>
                    <input value={"TEAM LEADER"} type='checkbox'  onChange={(e) => handleOnChange1(e)}/>
                    <h5>TEAM LEADER</h5>
                </div>
                <div>
                    <input value={"MANAGER"} type='checkbox'  onChange={(e) => handleOnChange1(e)}/>
                    <h5>MANAGER</h5>
                </div>
                <div>
                    <input value={"CARE COORDINATOR"} type='checkbox'  onChange={(e) => handleOnChange1(e)}/>
                    <h5>CARE COORDINATOR</h5>
                </div>
             </Menu>
             <br/><br/><br/>
             <Menu>
                Do You Drive ?*
                <br/>
                <select  onChange={handeleDriveChange}>
                   {YesNo.map((option) => (
                    <option key={option} value={option}>{option}</option>
                    ))}
                </select>
            </Menu>
            <br/>
             <Menu>
               Shift Pattern *
                <br/>
                <select  onChange={handeleShiftChange}>
                    {shifts.map((option) => (
                    <option key={option} value={option}>{option}</option>
                    ))}
                </select>
            </Menu>
            <Menu>
              Full Time (35hr+) or Part Time (16- 20hr) *
                <br/>
                <div>
                    <input value={"Full time"} type='checkbox' 
                      onChange={(e) => handleOnChange(e)}/>
                    <h5>Full time</h5>
                </div>
                <div>
                    <input value={"Part time"}  type='checkbox'
                      onChange={(e) => handleOnChange(e)}/>
                    <h5>Part time</h5>
                </div>
                <div>
                    <input value={"Contract"}  type='checkbox'
                      onChange={(e) => handleOnChange(e)}/>
                    <h5>Contract</h5>
                </div>
                <div>
                    <input value={"New Option"} type='checkbox'
                      onChange={(e) => handleOnChange(e)}/>
                    <h5>New Option</h5>
                </div>
            </Menu>
            <br/><br/><br/>
            <Menu>
              Work Permit
                <br/>
                 <select  onChange={handeleWorkChange}>
                    {workPermit.map((option) => (
                      <option key={option} value={option}>{option}</option>
                    ))}   
                 </select>
            </Menu>
            <div id='btnBottom'>
              <h4>EMPLOYMENT HISTORY (Company 1)</h4>
                <Input>
                    <textarea placeholder=' EMPLOYMENT HISTORY (Company 1) *' type='text'  value={employment1}  onChange={(e) => setEmployment1(e.target.value)}/>
                </Input>
                <Input>
                <RiAccountCircleLine size={35} color='#e1e1e1' />
                   <input placeholder='JOB ROLE, START - FINISH DATE *' type='text'  value={jobRole}  onChange={(e) => setjobRole(e.target.value)}/>
                </Input>
                <Input>
                    <RiAccountCircleLine size={35} color='#e1e1e1' />
                    <input placeholder='EMPLOYMENT HISTORY (Company 2) *' type='text'  value={employment2}  onChange={(e) => setEmployment2(e.target.value)}/>
                </Input>
                <Input>
                <RiAccountCircleLine size={35} color='#e1e1e1' />
                   <input placeholder='JOB ROLE, START - FINISH DATE *' type='text'  value={jobRole2}  onChange={(e) => setjobRole2(e.target.value)}/>
                </Input>
                <Input>
                    <RiAccountCircleLine size={35} color='#e1e1e1' />
                    <input placeholder='EMPLOYMENT HISTORY (Company 3) *' type='text'  value={employment3}  onChange={(e) => setEmployment3(e.target.value)}/>
                </Input>
                <Input>
                    <RiAccountCircleLine size={35} color='#e1e1e1' />
                    <input placeholder='JOB ROLE. START - FIINISH TIME *' type='text'  value={jobRole3}  onChange={(e) => setjobRole3(e.target.value)}/>
                </Input>
                <Input>
                    <RiAccountCircleLine size={35} color='#e1e1e1' />
                    <input placeholder='EDUCATION HISTORY (School 1)' type='text'  value={educationhis1}  onChange={(e) => seteducationhis1(e.target.value)}/>
                </Input>
                <Input>
                    <RiAccountCircleLine size={35} color='#e1e1e1' />
                    <input placeholder='EDUCATION HISTORY (School 2)' type='text'  value={educationhis2}  onChange={(e) => seteducationhis2(e.target.value)}/>
                </Input>
                <Input>
                    <textarea placeholder=' REFERENCE 1 (full name and contact details) *' type='text'  value={ref1}  onChange={(e) => setRef1(e.target.value)}/>
                </Input>
                <Input>
                    <RiAccountCircleLine size={35} color='#e1e1e1' />
                    <input placeholder='REFERENCE 2 (full name and contact details) *' type='text'  value={ref2}  onChange={(e) => setRef2(e.target.value)}/>
                </Input>
                <Input>
                    <textarea placeholder='TELL US ABOUT YOURSELF: *' type='text'  value={aboutSelf}  onChange={(e) => setaboutSelf(e.target.value)}/>
                </Input>
                <Input>
                    <RiAccountCircleLine size={35} color='#e1e1e1' />
                    <input placeholder='DATE OF APPLICATION *' type='text'  value={doa}  onChange={(e) => setDoa(e.target.value)}/>
                </Input>
                <br/>
                <Submit onClick={(e) => SendRegisteration()}> {!load ? "SEND" : <Oval color='#fff' height={20} width={20} />}</Submit>
            </div>

           
        </Form>
        <Discailmer>
            <h1>Important Disclaimer</h1>
            <h5>Ornate healthcare does not use any agent or agency  
                for recruitment of international or local staff. 
                Ornate Healthcare will never ask you to pay money towards any sponsorship.  
                Do not deal or work with anyone on our behalf or possing as an agent of our business. 
                We do not charge for Visa sponsorship. please beware of scammer
            </h5>
        </Discailmer>
    </Container>
  )
}


const Container = styled.div`
display:flex; 
flex-flow:row;
width:80%;
margin:0 auto;
justify-content:space-between;
#btnBottom{
margin-left:20px;
margin-top:20px; 
h4{
margin-left:30px;
}
}
@media(max-width:980px){
flex-flow:column;
width:90%;
#btnBottom{
margin-left:0px;
}
}
`;

const Discailmer = styled.div`
width:50%;
height:200px;
margin-top:50px;
margin-left:10px;
text-align:center;
background:#887BCA;
color:#fff;
border-radius:15px;
box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
h5{
text-align:left;
font-size:11px;
padding:10px;
}
h1{
padding:15px;
}
@media(max-width:980px){
width:90%;
height:200px;

}
`;


export const Menu = styled.div`
width:auto; 
height:auto;
margin-top:20px;
margin-left:40px;
div{
display:flex;
}
select{
width:100%;
height:30px;
border:1px solid #e1e1e1;
}
`;

export default WorkUs