import React from 'react'
import styled from 'styled-components'

function HomePageM(props) {

  return (
        <ImgText>
             <img src={props.v.url}/>
             <h4>{props.v.info}</h4>
             <span>
               {props.v.text}
             </span>
        </ImgText>
  )
}







const ImgText = styled.div`
display:flex;
flex-flow:column;
width:30%;
margin-top:45px;
img{
height:200px;
width:300px;
}
h4{
width:90%;
text-align:left;
}
span{
width:90%;
height:300px;
font-size:10px;
text-align:left;
}
:hover{
transform: scale(1.05);
transition: transform 500ms;
z-index: 1;
}
@media(max-width:980px){
width:90%;
img{
width:100%;
}
span{
height:auto;
}
}
`;


export default HomePageM