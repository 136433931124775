import React, { useState } from 'react'
import styled from 'styled-components'
import { Form, Input,Submit } from './ContactUs';
import {RiAccountCircleLine, RiArrowRightCircleLine} from 'react-icons/ri';
import { Oval } from 'react-loader-spinner';
import { MessagePop, Trim } from '../Admin/Reuse';
import { SendBooking } from '../Admin/Index';
import { Menu } from './WorkUs';

const  BookOrder = ()  => {


  const [name, setName] = useState("");  
  const [address,setAddress] = useState("");
  const [email,setEmail] = useState("");
  const [contact,setContact] = useState("");
  const [aboutService,setAboutService] = useState("");
  const [time,setTime] = useState("");
  const [gender, setGender] = useState("Select Gender");
  const [caretype, setCaretype] = useState("Select Type");
  const [genderList, genderisList] = useState(["Select Gender","Male","Female","Perfer not to say"]);
  const [careList, setcareList] = useState(["Select Type","Weekend only care","Live in care","Daliy care","Night (SLEEP OR WALK)","I don't know i will like to discuss"]);
  const [load, setLoad]= useState(false);



  const SendRegisteration = async () => {

    if(Trim(name) && Trim(address) && Trim(email) && Trim(contact) && Trim(aboutService) && Trim(time)){
          if(gender !== "Select Gender" && caretype !== "Select Type" ){
             setLoad(true);
             let res = await SendBooking([name,address,email,contact,aboutService,time,gender,caretype]);
             if(res){
               setLoad(false);
               MessagePop("Info",res.message,"success");
               setName(""); setAboutService(""); setAddress(""); setAboutService(""); setEmail(""); setContact(""); setTime("");
             }
          }else 
             MessagePop("Info","Pls select service type.","info");
    }else 
       MessagePop("Info","Pls all fields are mandatory.","info")

  };


  const handeleGenderChange =(e) => {
    setGender(e.target.value);
}

const handeleCareChange =(e) => {
    setCaretype(e.target.value);
}

  return (
    <Container>
        <Left>
          <h1>NEED ASSESSMENT FORM</h1> <RiArrowRightCircleLine id='icon'  style={{marginRight:"10px"}} size={65}/>
        </Left>
          <Form>
                 <Input>
                    <RiAccountCircleLine size={35} color='#e1e1e1' />
                    <input placeholder='Name *' type='text'  value={name}  onChange={(e) => setName(e.target.value)}/>
                </Input>
                <Menu>
                   SEX*
                    <br/>
                    <select onChange={handeleGenderChange}>
                      {genderList.map((option) => (
                       <option key={option} value={option}>{option}</option>
                      ))}
                     </select>
                </Menu>
                <Input>
                    <RiAccountCircleLine size={35} color='#e1e1e1' />
                    <input placeholder='FULL HOME ADDRESS (WHERE ASSESSMENT COULD BE DONE) *' type='text'  value={address}  onChange={(e) => setAddress(e.target.value)}/>
                </Input>
                <Input>
                    <RiAccountCircleLine size={35} color='#e1e1e1' />
                    <input placeholder='Email *' type='text'  value={email}  onChange={(e) => setEmail(e.target.value)}/>
                </Input>
                <Input>
                    <RiAccountCircleLine size={35} color='#e1e1e1' />
                    <input placeholder='CONTACT NUMBER  *' type='number'  value={contact}  onChange={(e) => setContact(e.target.value)}/>
                </Input>
                <Menu>
                   TYPE OF CARE NEEDED *
                    <br/>
                    
                    <select onChange={handeleCareChange}>
                    {careList.map((option) => (
                      <option key={option} value={option}>{option}</option>
                     ))}
                    </select>
                </Menu>
                <Input>
                    <textarea placeholder='TELL US ABOUT YOUR CARE NEEDS: *' type='text'  value={aboutService}  onChange={(e) => setAboutService(e.target.value)}/>
                </Input>
                <Input>
                    <RiAccountCircleLine size={35} color='#e1e1e1' />
                    <input placeholder='TELL US WHEN YOU WOULD LIKE TO BE ASSESSED *' type='text'  value={time}  onChange={(e) => setTime(e.target.value)}/>
                </Input>
                <br/>
                <Submit onClick={(e) => SendRegisteration()}> {!load ? "SEND" : <Oval color='#fff' height={20} width={20} />}</Submit>
        </Form>
    </Container>
  )
}


const  Container  = styled.div`
width:80%; 
height:auto;
display:flex; 
justify-content:center;
flex-flow:row;
margin: 0 auto;
@media(max-width:768px){
flex-flow:column;
width:90%;
}
`;

const Left = styled.div`
width:60%; 
height:300px;
margin-top:50px;
display:flex;
flex-flow:column;
align-items:left;
h1{
width:100%; 
font-weight:900;
font-size:50px;
font-style:bold;
}
@media(max-width:768px){
width:100%;
height:auto;
h1{
font-size:25px;
text-align:center;
}
#icon{
display:none;
}
}
`;



export default BookOrder