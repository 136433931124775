import React, { useState } from 'react'
import styled from 'styled-components'
import { Form, Input, Submit } from '../Components/ContactUs';
import { Oval } from 'react-loader-spinner';
import {RiAccountCircleLine, RiLock2Line} from 'react-icons/ri'
import { AdminLogin } from './Index';
import { MessagePop, Trim, SessionStore} from './Reuse';
import { useNavigate } from 'react-router-dom';


const Login = () =>{

  const [load, setLoad] = useState(false);
  const  [email, setEmail] = useState("")
  const  [password, setPassword] = useState("");
  const history = useNavigate();


  const SendRegisteration = async () => {
    if(Trim(email)  && Trim(password)){
      setLoad(true);
      let res = await AdminLogin([email,password]);
      console.log(res);
          if(JSON.stringify(res.message).includes("successfully")){
            setLoad(false);
            SessionStore([email,res.message.token]);
            window.location.href = "/cpanel";
          }
        MessagePop("Info",res.message.title,"info");
     }else
        MessagePop("Info","Pls all fields are mandatory.","info")
  }

  return (
    <Container>
      <Form>
       Admin Login
      <Input>
          <RiAccountCircleLine size={35} color='#e1e1e1' />
          <input placeholder='Email *' type='email'  value={email}  onChange={(e) => setEmail(e.target.value)}/>
      </Input>
      <Input>
          <RiLock2Line size={35} color='#e1e1e1' />
          <input placeholder='Password *' type='password'  value={password}  onChange={(e) => setPassword(e.target.value)}/>
      </Input>
       <br/>
          <Submit onClick={(e) => SendRegisteration()}> {!load ? "Login" : <Oval color='#fff' height={20} width={20} />}</Submit>
          <div style={{margin:"0 auto",paddingTop:"25px"}} onClick={(e) => history("/auth2")}>Register</div>
      </Form>
    </Container>
  )
}



const Container = styled.div`
width:50%; 
height:100vh;
margin-left:auto;
margin-right:auto;
margin-top:10%;
@media(max-width:980px){
width:90%;
margin-top:50%;
}
`;

export default Login