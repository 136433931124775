import React, { useState } from 'react'
import styled from 'styled-components'
import Footer from './Footer';
import { Oval } from 'react-loader-spinner';
import { RiAccountCircleLine, RiMailAddLine, RiMessageLine, RiPhoneLine, RiUserLocationLine } from 'react-icons/ri';
import Map, {GeolocateControl} from 'react-map-gl';
import { SendEnquire } from '../Admin/Index';
import { MessagePop, Trim } from '../Admin/Reuse';
import GoogleMapReact  from 'google-map-react';



const  ContactUs = (props) => {

  const [name, setName]  = useState("");  
  const [email, setEmail]  = useState("");  
  const [phone, setPhone]  = useState("");  
  const [message, setMessage]  = useState("");  
  const [load, setLoad] = useState(false);
  const [url, setUrl] = useState(process.env.REACT_APP_LOCATION);  

  const SendRegisteration = async () => {
    if(Trim(name) && Trim(email) && Trim(phone) && Trim(message)){
           setLoad(true);
           let res = await SendEnquire([name,email,phone,message]);
           if(res){
             setLoad(false);
             MessagePop("Info",res.message,"info");
             setName(""); setEmail(""); setPhone(""); setMessage("");
           }
     }else 
        MessagePop("Info","Pls all fields are mandatory.","info")
  }

  
  const defaultProps = {
    center: {    
      lat: 53.483732141278544,
      lng: -2.3196648608519683
    },
    zoom: 11
  };


  return (
    <Contanier>
        <MapService> 
         <GoogleMapReact
                    bootstrapURLKeys={{key:process.env.REACT_APP_URL}}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                   >
                     <AnyReactComponent
                        lat={53.48633935070407}
                        lng={-2.3389467420124075}
                        text="My Marker">
                            <img alt='Ornatehealthcare Logo' src="../assets/pngegg.png"/>
                        </AnyReactComponent>
            </GoogleMapReact>
        </MapService>
        <Bottom>
           <Left>
              <h3>Thank you for reaching out to us</h3>
              <h4 style={{fontStyle:"italic"}}>
                    At Ornate Healthcare, we are confident that we will respond to your querries within 24hrs. 
                    Please do not hesitate to connect with us on social medial handles  such as Instagram, facebook and twitter.
              </h4>
              <h5>Request for more information about our services by filling the form below and one of our colleague will be in contact shortly..</h5>
              <Form>
              <Input>
                <RiAccountCircleLine size={35} color='#e1e1e1' />
                <input placeholder='Name' type='text'  value={name}  onChange={(e) => setName(e.target.value)}/>
              </Input>
              <Input>
                <RiMailAddLine size={35} color='#e1e1e1' />
                 <input placeholder='Email' type='email'  value={email}  onChange={(e) => setEmail(e.target.value)}/>
              </Input>
              <Input>
                <RiPhoneLine size={35} color='#e1e1e1' />
                <input placeholder='Phone no' type='number'  value={phone}  onChange={(e) => setPhone(e.target.value)}/>
              </Input>
              <Input>
                <textarea placeholder='Message' type='text'  value={message}  onChange={(e) => setMessage(e.target.value)}/>
              </Input>
               <Submit onClick={(e) => SendRegisteration()}> {!load ? "SEND" : <Oval color='#fff' height={20} width={20} />}</Submit>
              </Form>
           </Left>
           <Right>
            <h3>Contact</h3>
            <h5>Greater Manchester Regional Office</h5>
            <div className='tabs'>
                <RiUserLocationLine className='ic' size={40}/>
                <div className='space'>
                    <span>
                        Salford Office Address
                        Suite 2, Bright House.Bright Road
                        Eccles. Manchester  M30 0WG
                    </span>
                </div>
                <div className='space'>
                    <span>
                    Trafford Office Address
                    Suite 6, Democracy House. Stretford
                    Trafford.  M16 0QA
                    </span>
                </div>
            </div>
            <div className='tabs'>
                <RiPhoneLine className='ic' size={40}/>
                <div className='space'>
                    <h5></h5>
                    <span>
                    + 44 - 1614650531  Mon -Sat 9am - 5pm
                     +44 - 7771104988   Emergency
                    </span>
                </div>
                <div className='space'>
                    <span>
                        + 44 - 7417439531  Mon -Sat 9am - 5pm
                        +44 - 7771104988   Emergency
                    </span>
                </div>
            </div>
            <div className='tabs'>
                <RiMailAddLine  className='ic' size={40}/>
                <div className='space'>
                    <h5></h5>
                    <span>
                        Manager
                        sylvia.ativie@ornatehealthcare.co.uk
                    </span>
                </div>
                <div className='space'>
                    <span>
                        Manager
                       sg.goldstraw@ornatehealthcare.co.uk
                  </span>
                </div>
            </div>
            <BottomRight>
                 <Box>
                    <h4>E. Midlands Regional Office</h4>
                    <div className='tabs'>
                        <RiUserLocationLine  className='ic' size={60}/>
                        <div className='space2'>
                            <span>
                                SSuite 1.2, Litchurch  Plaza.
                                Litchurch Lane. Derby
                                Derby.
                                DE24 8AA
                            </span>
                        </div>
                    </div>
                    <div className='tabs'>
                        <RiPhoneLine className='ic' size={60}/>
                        <div className='space2'>
                            <span>               
                                +44 - 1332653430
                                +44 - 7466237177   Emergency
                            </span>
                        </div>
                    </div>
                    
                    <div className='tabs'>
                        <div className='space2'>
                        <RiMailAddLine  size={50}/>
                            <span>               
                               derby@ornatehealthcare.co.uk
                            </span>
                        </div>
                    </div>
                 </Box>
                 <Box>
                    <h4>Merseyside Office</h4>
                    <div className='tabs'>
                        <div className='space2'>
                            <span>               
                                Suite 9, Sterling House.
                                Bridle Way. Bootle
                                Liverpool
                                L30 4UJ
                            </span>
                        </div>
                    </div>
                    <div className='tabs'>
                        <div className='space2'>
                            <span>               
                                +44 - 03301656505
                                +44 - 7466237177   Emergency
                            </span>
                        </div>
                    </div>
                    <div className='tabs'>
                        <div className='space2'>
                        <RiMailAddLine  size={50}/>
                            <span>                   
                              merseysidereferrals@ornatehealthcare.co.uk
                            </span>
                        </div>
                    </div>
                 </Box>
            </BottomRight>
           </Right>
        </Bottom>
    </Contanier>
  )
}

const Contanier = styled.div`
width:90%;
margin: 0 auto;
`;


const MapService = styled.div`
width:100%;
height:300px;
margin-top:40px;
`;



const Bottom = styled.div`
width:100%;
display:flex;
flex-flow:row;
justify-content:space-between;
@media(max-width:980px){
flex-flow:column;
}
`;


const Right = styled.div`
width:45%;
height:auto;
h3{
margin-top:50px;
}
.tabs{
display:flex;
justify-content:space-between;
align-items:center;
flex-flow:row;
width:100%; 
height:100px;
font-size:11px;
margin-top:10px;
h5{
font-size:12px;
}
.space{
width:40%;
text-align:left;
}
.space2{
width:100%;
text-align:left;
}
}
@media(max-width:980px){
width:100%;
.tabs{
.space{
white-space: pre-wrap;
white-space: -moz-pre-wrap;
white-space: -pre-wrap;
white-space: -o-pre-wrap;
word-wrap: break-word;
}
.space2{
white-space: pre-wrap;
white-space: -moz-pre-wrap;
white-space: -pre-wrap;
white-space: -o-pre-wrap;
word-wrap: break-word;
}
}
}
`;

const Left = styled.div`
width:50%;
height:auto;
h3{
margin-top:50px;
}
h4{
margin-left:0px;
margin-top:20px;
font-size:13px;
}
h5{
font-size:12px;
margin-top:35px;
}
@media(max-width:980px){
width:95%;
}
`;


export const Form = styled.div`
display:flex;
width:80%;
padding:40px;
height:auto;
flex-flow:column;
margin-left:auto;
margin-right:auto;
margin-top:50px;
margin-bottom:20px;
background:${(props) => props.ty ? "gray" : "#fff"};
border-radius:10px;
box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
@media(max-width:768px){
width:90%;
padding:20px;
}
`;





export const Input = styled.div`
width:100%;
display:flex;
justify-content:center;
align-items:center;
margin-top:20px;
input{
width:100%;
height:30px;
border:1px solid #e1e1e1;
border-radius:2px;
margin-left:10px;
padding:10px;
}
textarea{
width:90%;
height:200px;
margin-left:30px;
padding:10px;
}
`;


export const Submit = styled.div`
width:80%;
height:25px;
color:#fff;
margin-left:auto;
margin-right:auto;
margin-top:${(props) => props.h  ? props.h : "50px"};
padding:10px;
display:flex;
justify-content:center;
text-align:center;
align-items:center;
border-radius:10px;
background:blue;
cursor:pointer;
`;



const BottomRight = styled.div`
width:100%;
height:auto;
display:flex;
margin-top:50px;
margin-bottom:150px;
justify-content:space-between;
`;


const Box = styled.div`
width:45%;
height:100%;
`;



const AnyReactComponent = styled.div`
height:120px; 
width:100px; 
img{
height:100%; 
width:100%; 
object-fit:contain;
}
`;

export default ContactUs